import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO/SEO'
import website from '../../config/website'
import { RichText } from 'prismic-reactjs'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { withPreview } from 'gatsby-source-prismic'
import LoadingSpinner from '../components/LoadingSpinner'

import Isotope from 'isotope-layout/js/isotope'
import 'isotope-packery'
import OnImagesLoaded from 'react-on-images-loaded'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

class FilterGrid extends React.Component {
  constructor(props) {
    super(props)
    this.handleLoad = this.handleLoad.bind(this)
    this.state = {
      showImages: false,
    }
  }

  componentDidMount() {
    this.handleLoad()
  }

  handleLoad() {
    this.layout = new Isotope('.work-grid', {
      itemSelector: '.work-list__tile',
      stagger: 30,
      percentPosition: true,
      layoutMode: 'packery',
    })
  }

  imageLayout() {
    this.layout.layout()
    ScrollTrigger.refresh()
  }

  imagesHaveLoaded() {
    this.layout.layout()
    setTimeout(() => {
      this.setState({ showImages: true })
      const trigger = [...document.querySelectorAll('.js-work-trigger')]
      trigger.map((el, index) => {
        const image = el.querySelector('.work-list__tile-image')
        const title = el.querySelector('.work-list__tile-title').children
        gsap.to(image, {
          autoAlpha: 1,
          ease: 'power4.out',
          duration: 1.5,
          scrollTrigger: {
            id: `work-list-${index + 1}`,
            trigger: el,
            start: 'top 70%',
            end: 'top top',
            once: true,
          },
        })
        gsap.fromTo(
          title,
          {
            y: 40,
          },
          {
            autoAlpha: 1,
            y: 0,
            ease: 'power4.out',
            duration: 1.5,
            scrollTrigger: {
              id: `work-list-${index + 1}`,
              trigger: el,
              start: 'top 90%',
              end: 'top top',
              once: true,
            },
          }
        )
      })
    }, 2000)
  }

  render() {
    const childElements = this.props.childElements

    return (
      <section className="module work-module -full-height">
        <div className="work-module__featured-title">
          {!this.state.showImages ? null : <h1>Featured work</h1>}
        </div>
        <div className="work-list">
          <div className="container work-grid" id="#workGrid">
            <OnImagesLoaded
              onLoaded={this.imagesHaveLoaded.bind(this)}
              onTimeout={this.imagesHaveLoaded.bind(this)}
              timeout={2000}>
              {this.state.showImages ? null : (
                <LoadingSpinner title="Loading..." />
              )}
              {childElements.map((element, index) => (
                <div
                  key={`item-${index}`}
                  className={`work-list__tile work-list__tile-${element.work_list_tile_size} || js-work-trigger`}>
                  <AniLink
                    cover
                    direction="right"
                    bg="#000000"
                    duration={1.5}
                    id={element.work_list_page_link.id}
                    to={
                      element.work_list_page_link
                        ? element.work_list_page_link.url
                        : ''
                    }>
                    <div
                      className={`work-list__tile-image ${element.overlay_opacity}`}
                      style={{ opacity: this.state.showImages ? 1 : 0 }}>
                      <img
                        onLoad={this.imageLayout.bind(this)}
                        className="work-list__tile-image__img lazyload"
                        alt={element.image.alt ? element.image.alt : ''}
                        data-src={
                          element.image.fluid.src ? element.image.fluid.src : ''
                        }
                        data-srcset={
                          element.image.fluid.srcSet
                            ? element.image.fluid.srcSet
                            : ''
                        }
                      />
                    </div>
                    <div className="work-list__tile-title">
                      <RichText render={element.title.raw || []} />
                    </div>
                  </AniLink>
                </div>
              ))}
            </OnImagesLoaded>
          </div>
        </div>
      </section>
    )
  }
}

export const WorkListTemplate = ({ data }) => {
  if (!data) return null

  const workList = data.prismicWorkList || {}
  const topMenu = data.prismicTopMenu || {}
  const work = workList.data.work_list_group
  const featuredWork = workList.data.featured_work

  const { type, url } = workList || {}
  const activeDoc = {
    type,
    url,
  }

  return (
    <>
      <SEO
        title={`${workList.data.display_title.text} | ${website.titleAlt}`}
        pathname={typeof window === 'undefined' ? '' : location.pathname}
        desc={workList.data.social_description_override || data.description}
        banner={workList.data.social_image_override.url}
        node={workList}
      />
      <section className="module work-module">
        <div className="featured-work">
          <AniLink
            cover
            direction="right"
            bg="#000000"
            duration={1.5}
            id={featuredWork.document.id}
            to={featuredWork.document.url}>
            <div className="featured-work__tile || js-work-trigger">
              <div className="work-list__tile-image">
                <img
                  className="work-list__tile-image__img lazyload"
                  alt={
                    featuredWork.document.data.work_list_image.alt !== null
                      ? featuredWork.document.data.work_list_image.alt
                      : featuredWork.document.data.display_title.text
                  }
                  data-src={
                    featuredWork.document.data.work_list_image
                      ? featuredWork.document.data.work_list_image.fluid.src
                      : ''
                  }
                  data-srcset={
                    featuredWork.document.data.work_list_image
                      ? featuredWork.document.data.work_list_image.fluid.srcSet
                      : ''
                  }
                />
              </div>
              <div className="work-list__tile-title">
                <h4>
                  {featuredWork.document.data.featured_work_list_sub_title.text}
                </h4>
                <RichText
                  render={featuredWork.document.data.display_title.raw || []}
                />
              </div>
            </div>
          </AniLink>
        </div>
      </section>
      <FilterGrid childElements={work} />
    </>
  )
}

export const query = graphql`
  query workListQuery {
    prismicWorkList {
      data {
        display_title {
          text
          raw
        }
        social_description_override
        social_image_override {
          url
        }
        featured_work {
          document {
            ... on PrismicWorkPage {
              id
              url
              data {
                display_title {
                  text
                  raw
                }
                work_list_image {
                  alt
                  url
                  fluid(imgixParams: { maxWidth: 800, fit: "crop" }) {
                    src
                    srcSet
                  }
                }
                featured_work_list_sub_title {
                  raw
                  text
                }
              }
            }
          }
        }
        work_list_group {
          title {
            html
            raw
            text
          }
          image {
            alt
            fluid(imgixParams: { maxWidth: 800, fit: "crop", auto: "format" }) {
              src
              srcSet
            }
          }
          work_list_page_link {
            id
            url
          }
          work_list_tile_size
          overlay_opacity
        }
      }
    }
    prismicTopMenu {
      ...TopMenuFragment
    }
  }
`

export default withPreview(WorkListTemplate)
